.projectPageMapTitle {
  font-family: "Crimson Text", serif;
  font-size: 1.7rem;
}

.projectPageSectionTitle {
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 15px;
  text-transform: uppercase;
}

.infobox {
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: white;
  padding: 15px 0px;
}
