body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.hover-el:hover {
  background-color: black;
  color: white;
}

.cursor-pointer {
  cursor: pointer;
}

.underline,
.header-el {
  position: relative;
}

.underline::after,
.header-el::after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  top: 21px;
  left: 0;
  opacity: 1;
  transition: 0.5s opacity;
  background-color: black;
}

.underline::after {
  opacity: 1 !important;
}

.header-el::after {
  opacity: 0;
}

.header-el:hover.header-el::after {
  opacity: 1;
  transition: 0.5s opacity;
}

a,
.link {
  cursor: pointer;
  position: relative;
  color: black;
}

a:hover,
.link:hover {
  color: black !important;
  /* text-decoration: none; */
}

/* a::after, */
.link::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 3px;
  left: 0;
  opacity: 0;
  transition: 0.2s opacity;
  background-color: black;
}

/* a:hover a::after, */
.link:hover.link::after {
  opacity: 1;
  transition: 0.2s opacity;
}

.viz-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.autocomplete-container {
  width: 100%;
  border-bottom: 1px solid #d7d7d7;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: 10px;
}

.autocomplete-icon {
  opacity: 0.5;
  position: absolute;
  left: 3px;
  margin-top: 5px;
  margin-right: 5px;
  z-index: 3;
}

input.autocomplete-input {
  width: 100%;
  position: relative;
  border: 0px;
  font-size: 13px;
  padding-top: 5px;
  padding-left: 25px;
  padding-bottom: 0.5rem !important;
}

/* LANDING */

.home-image {
  width: 150px;
}

.landing-container {
  background: linear-gradient(
    24deg,
    rgba(48, 35, 174, 0.57),
    rgba(83, 188, 253, 0.57) 14%,
    rgb(190, 71, 185, 0.57) 43%,
    rgba(236, 212, 81, 0.57)
  );
  /* background-image: linear-gradient(
    -197deg,
    #e9c6bc 19%,
    #eacf9a 44%,
    #86ceeb 67%
  ); */
  animation: Gradient 10s ease infinite;
  animation-direction: alternate;
  background-size: 200% 200%;
  background-position: 0 50%;
}

.landing-text-container-upper {
  font-family: "Source Sans Pro", serif;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.4;
  margin-bottom: 36px;
  color: white;
}

.landing-text-container-lower {
  font-family: "Crimson Text", serif;
  font-size: 20px;
  line-height: 1.3;
  color: white;
  column-count: 1;
}

.landing-link {
  font-size: 20px;
  cursor: pointer;
  margin: 75px auto;
  color: white;
  position: relative;
}

.landing-link::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: -1px;
  left: 0;
  background-color: transparent;
}

.landing-link:hover::after {
  background-color: white;
}

.footer-desc {
  font-family: "Crimson Text", serif;
}

.rainbow-line::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: -1px;
  left: 0;
  opacity: 1;
  transition: 0.5s opacity;
  background: linear-gradient(to right, #ff0000, #00ff26);
  background-size: 200% 200%;
  background-position: 0% 50%;
  animation: Gradient 15s ease infinite;
  animation-direction: alternate;
}

/* HEADER */

.header {
  /* width: 100%;
  display: flex;
  position: relative;
  overflow: hidden; */
  background: linear-gradient(
    86deg,
    rgba(48, 35, 174, 0.57),
    rgba(83, 188, 253, 0.57) 14%,
    rgb(190, 71, 185, 0.57) 43%,
    rgba(236, 212, 81, 0.57)
  );
  animation: Gradient 15s ease infinite;
  animation-direction: alternate;
  background-size: 200% 200%;
  background-position: 0 50%;
}

.header.small {
  height: 12px !important;
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.header-content {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
}

.header-el-not-link {
  font-size: 14px;
  color: black;
}

.header-el {
  font-size: 12px;
  color: black;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.nav-item {
  margin: 10px 20px;
  display: flex;
}

/* PROJECTS */

.project-counter {
  width: 100%;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 12px;
  display: flex;
  align-items: center;

  height: 32px;
}

.tag {
  height: 30px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 2px 4px 2px rgba(217, 217, 217, 0.56);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 6px;
  margin: 10px 10px 10px 0;
  font-size: 14px;
  transition: 0.2s all;
}

.tag:hover {
  background-color: black;
  color: white;
  transition: 0.2s all;
}

.path {
  fill: black;
  transition: 0.2s all;
}

.tag:hover .path {
  fill: white;
  transition: 0.2s all;
}

.project-header {
  position: sticky;
  background-color: white;
  border-bottom: 1px solid #d7d7d7;
  z-index: 1;
  top: 0;
  padding-top: 10px;
}

.project-list a {
  display: block;
  padding: 1rem 1rem 1rem 0;
  border-bottom: 1px solid #d7d7d7;
}

.project-list a:hover {
  text-decoration: none;
  border-bottom: 1px solid black;
}

.project {
  transform: translateX(0);
  transition: transform 0.2s;
}

.project:hover {
  transform: translateX(1rem);
}

.project-title {
  font-size: 26px;
  font-family: "Crimson Text", serif;
}

.project-researchers {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 5px;
  text-transform: uppercase;
}

.project-body {
  line-height: 1.5;
  margin-top: 15px;
  font-family: "Crimson Text", serif;
}

.project-country-labels {
  display: flex;
  align-items: center;
  font-size: 11px;
  opacity: 0.5;
}

.project-country-labels .material-icons {
  font-size: 12px !important;
}

.annotation-note-title {
  font-weight: normal !important;
  text-transform: uppercase;
  cursor: text !important;
}

/* LOCATIONS */

.location-block {
  width: 33.3%;
  height: 16vw;
  transition: 0.2s all;
  cursor: pointer;
  position: relative;
  border: 2px solid white;
}

.location-map {
  transform: scale(1);
  transition: 0.2s all;
  z-index: 0;
}

/* .location-block:active {
  border: 2px solid black;
} */

.location-block.active .location-map,
.location-block:hover .location-map {
  transform: scale(1.2);
  transition: 0.2s all;
}
.location-block.active .location-text,
.location-block:hover .location-text {
  background-color: rgba(255, 255, 255, 0);
}

/* .underline-location::after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  top: 21px;
  left: 0;
  opacity: 1;
  transition: 0.5s opacity;
  background-color: black;
} */

.location-block.active .location-country,
.location-block.active .location-city {
  font-weight: 600;
}

.location-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
  transition: background-color 0.2s;
}

.location-country {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: relative;
}

.location-city {
  font-size: 18px;
  font-weight: 300;
  position: relative;
}

/* TIME */

.time-block {
  border-bottom: 1px solid #d7d7d7;
  border-right: 1px solid #d7d7d7;
  border-left: 1px solid #d7d7d7;
  background-color: white;
  color: black;
  transition: 0.2s all;
  cursor: pointer;
}

.time-block.active,
.time-block:hover {
  background-color: black;
  color: white;
  transition: 0.2s all;
}

/* METHOD */

.method-block {
  width: 100%;
  border-top: 1px solid #d7d7d7;
  background-color: white;
  color: black;
  transition: 0.2s all;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.method-block:nth-child(1) {
  border-top: 0px solid #d7d7d7;
}

.method-block.active,
.method-block:hover {
  background-color: black;
  color: white;
  transition: 0.2s all;
}

.tooltip-extra-class {
  pointer-events: auto !important;
  background-color: black !important;
  border-radius: 0;
  padding: 5px;
  font-size: 10px;
  font-family: "Source Sans Pro", sans-serif;
}

/* TOPIC */

.topic-block-line {
  width: 90%;
  height: 2px;
  background-color: black;
  opacity: 0;
  transition: 0.2s opacity;
}

.topic-block-line.active {
  opacity: 1;
  transition: 0.2s opacity;
}

/* VIZ */

.rect {
  pointer-events: none;
}

.circle {
  cursor: pointer;
}

.circle,
.rect {
  fill: white;
}

.circle.active,
.rect.active {
  fill: black;
}

.text {
  fill: black;
  font-size: 9px;
  text-transform: uppercase;
  dominant-baseline: central;
  pointer-events: none;
}

.text.active {
  fill: white;
}

@media (min-width: 576px) {
}

/* md */
@media (min-width: 768px) {
  .landing-text-container-lower {
    font-size: 24px;
    line-height: 1.4;
    column-count: 2;
  }
  .home-image {
    width: 250px;
  }
  .landing-text-container-upper {
    font-size: 34px;
    line-height: 1.47;
  }

  .nav-item {
    margin: 0 20px;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
