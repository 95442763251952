.project-page-title {
  font-family: "Crimson Text", serif;
  font-size: 50px;
  line-height: 3.3rem;
  margin: 15px 0px 11px 0px;
}

.project-page-researcher {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
}

.project-page-body {
  font-family: Crimson Text;
}

.project-page-body p {
  font-family: "Crimson Text", serif;
  font-size: 24px;
  line-height: 1.5;
}

.project-page-body h3 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.7rem;
  margin-top: 25px;
  font-weight: 300;
}

.project-page-section-title {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.project-page-section-title-no-padding {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 15px;
  text-transform: uppercase;
  padding-left: 0px;
}

.project-page-resource {
  font-family: "Crimson Text", serif;
  font-size: 16px;
  position: relative;
  margin-bottom: 15px;
}

.project-page-resource::after {
  content: "";
  width: 10%;
  height: 1px;
  position: absolute;
  bottom: -7px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.project-page-resource .resource-item {
  display: block;
  transform: translateX(0);
  transition: transform 0.2s linear;
  cursor: pointer;
}
.project-page-resource .resource-item:hover {
  text-decoration: none;
  transform: translateX(5px);
}

.resource-category {
  margin-right: 5px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.resource-container,
.resource-container > p {
  display: inline;
}

.close-icon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  box-shadow: 1px 1px 2px 1px rgba(173, 173, 173, 1);
  position: sticky;
  top: 24px;
  margin: 19px 0px;
  z-index: 1;
  background-color: white;
}

.close-icon-container {
  position: sticky !important;
  top: 24px;
  z-index: 1;
}

.project-page-special-title {
  font-family: "Crimson Text", serif;
  font-size: 24px;
  margin-bottom: 11px;
}

.feedback {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px 0px;
  margin: 15px 0px;
  cursor: pointer;
  font-size: 12px;
}

.photoCredits span {
  font-size: 0.875rem;
  font-weight: normal;
}

.photoCredits {
  font-weight: 600;
}

@media (min-width: 576px) {
}

/* md */
@media (min-width: 768px) {
  .close-icon-container {
    position: relative;
  }
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}
