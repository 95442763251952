.carousel {
  /* margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 80px; */
  width: 100%;
  margin-bottom: 15px;
}

/* SLIDER */

.slider {
  height: 600px;
  margin-bottom: 25px;
}

.slider-half {
  height: 300px;
  margin-bottom: 50px;
}

.slider-image {
  max-height: 600px;
  margin-right: 30px;
}

.slider-arrows {
  position: relative;
  left: 0;
  top: 0;
  width: 150px;
  height: 50px;
  background-color: white;
  box-shadow: 0 2px 4px 2px rgba(217, 217, 217, 0.56);
  border-radius: 45px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
