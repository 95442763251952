.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-columns: 8fr 2fr 2fr;
  column-gap: 16px;
}

.grid .cell {
  padding: 8px;
  border-bottom: 1px solid #d7d7d7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid .cell p {
  margin: 0;
  font-family: "Crimson Text", serif;
}

.grid .header {
  text-transform: uppercase;
  font-weight: bold;
  justify-content: space-between !important;
  border-bottom: 1px solid black !important;
  font-size: 0.75rem;
}

.order {
  cursor: pointer;
}

.sticky {
  position: sticky;
  top: 0px;
  background: white;
  z-index: 1;
}

.gridContainer {
  height: 100%;
  overflow-y: auto;
}

.download {
  margin-top: 5px;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
}

.download:hover {
  text-decoration: none !important;
}

.download > span:last-of-type {
  font-size: 1rem !important;
}

.autocomplete-container {
  width: 100%;
  border-bottom: 1px solid black;
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: 10px;
  margin-top: 20px;
}

.autocomplete-icon {
  opacity: 0.5;
  position: absolute;
  left: 3px;
  margin-top: 5px;
  margin-right: 5px;
  z-index: 3;
}

input.autocomplete-input {
  width: 100%;
  position: relative;
  border: 0px;
  font-size: 13px;
  padding-top: 5px;
  padding-left: 25px;
  padding-bottom: 0.5rem !important;
}
