.right-column {
  position: sticky;
  top: 24px;
}

.list-title {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
}

.list-el {
  font-size: 14px;
  line-height: 1.5;
}
